import React from "react"
import { Link} from "gatsby"

const GetStartedMenu = () => {
    return (
        <ul className="menu-list">
            <li>
                <Link to="/download">
                    アプリダウンロード
                </Link>
            </li>
            <li>
                <Link to="/document/tutorial">
                    チュートリアル
                </Link>
            </li>
            <li>
                <Link to="/document/requirements">
                    動作環境
                </Link>
            </li>
        </ul>
    )
}
export default GetStartedMenu;