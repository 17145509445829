import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const EndUserMenu = () => {
    const endUserMenu = useStaticQuery(graphql`
        query EndUserMenuQuery {
            allMdx(
                filter: {
                    frontmatter: {
                        category: {eq: "help"}
                        subcategory: {eq: "end_user"}
                        slug: {nin: ["/document/tutorial", "/document/requirements"]}
                    }
                }
                sort: {fields: [frontmatter___priority], order: ASC}
            ) {
                edges {
                    node {
                        frontmatter {
                            title
                            slug
                        }
                    }
                }
            }
        }`);
    return (
        <ul className="menu-list">
            {endUserMenu.allMdx.edges.map(({ node }) => (
            <li>
                <Link to={`${node.frontmatter.slug}`}>
                    {node.frontmatter.title}
                </Link>
            </li>
            ))}
        </ul>
    )
}

export default EndUserMenu;