import React from "react"
import Header from "../header"
import Footer from "../footer"
import DocumentMenu from "../menu_document"
import MobileMenu from "../mobile_menu"

const DocumentLayout = ({ children }) => {
    return (
        <div className="portal-container">
            <Header />
            <div className="portal-item">
                <MobileMenu />
                <div className="columns is-flex is-flex-direction-column is-align-items-center">
                    <div className="column is-two-thirds">
                        <div className="columns">
                            <div className="column is-3 my-6">
                                <DocumentMenu />
                            </div>
                            <div className="column my-6 mx-4 content">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default DocumentLayout;