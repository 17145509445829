import React from "react"
import { Link } from "gatsby"
import "../styles/mystyles.scss"
import DocumentLayout from "../components/layout/document"

const Document = ({data}) => {
  return (
    <DocumentLayout>
      <p>本ページではBookLooperのドキュメントを提供します。ドキュメントは次の内容が含まれています。</p>
      <ul>
        <li>利用者向け</li>
        <ul>
          <li>アプリの設定方法</li>
          <li>アプリの使い方</li>
        </ul>
        <li>共通</li>
        <ul>
          <li>よくある質問</li>
        </ul>
      </ul>
      初めてBookLooperアプリをお使いの場合は<Link to="/document/tutorial">チュートリアル</Link>をご覧ください。
    </DocumentLayout>);
}

// export const query = graphql`
// query DocumentQuery {
//   allMarkdownRemark(
// 	filter: {
//       frontmatter: {category: {eq: "help"}}
//     }
//   ) {
//     edges {
//       node {
//         id
//         html
//         frontmatter {
//           title
//           date(formatString: "YYYY年MM月DD日")
//           keywords
//           slug
//           category
//         }
//       }
//     }
//   }
// }
// `
export default Document;