import React from "react"
import { Link } from "gatsby"
import GetStartedMenu from "./menu/get_started"
import EndUserMenu from "./menu/enduser"

const DocumentMenu = () => {
  return (
    <aside class="menu mx-4">
      <p class="menu-label">
        ドキュメント
      </p>
      <ul className="menu-list">
        <li>
          <Link to="/document">ホーム</Link>
        </li>
      </ul>
      <p class="menu-label">
        はじめに
      </p>
      <GetStartedMenu />
      <p class="menu-label">
        利用マニュアル
      </p>
      <EndUserMenu />
     </aside>
    );
}

export default DocumentMenu;